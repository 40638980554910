@import "../../../../assets/scss/index.scss";

.page_404 {
  ul {
    .u_menu_item {
      color: #fff;
    }
  }
}

.u_header--scrolled {
  ul {
    .u_menu_item {
      color: #0b1524;
    }
  }
}

ul {
  li,
  a {
    text-decoration: none;

    .u_menu_item {
      display: inline-flex;
      flex-wrap: wrap;
      align-items: flex-end;
      height: 80px;
      cursor: pointer;
      font-weight: 600;
      font-size: 14px;
      line-height: 17px;
      color: #0b1524;

      @include respond(lg) {
        font-weight: 600;
        font-size: 32px;
        line-height: 40px;
        display: block;
        padding: 12px 0;
        height: auto;
        align-items: center;
        width: 100%;
      }

      @include respond(sm) {
        display: block;
        font-size: 25px;
        line-height: 32px;
      }

      &:after {
        content: "";
        width: 100%;
        height: 4px;
        display: block;
        background-color: transparent;
        border-radius: 20px;
        position: relative;
        left: -15px;
        padding: 0 15px;

        @include respond(lg) {
          left: unset;
          padding: 0;
          width: calc(100% - 58px);
          margin: 8px 0 0;
          transition: background-color 0.5s ease;
        }
      }
    }

    &:hover {
      .u_menu_item {
        color: $blue;
        &:after {
          background-color: $primary-bg;
          @include respond(lg) {
            background-color: transparent;
          }
        }
      }
    }
  }
}

@include respond(md) {
  ul {
    li {
      ul + a {
        .u_menu_item {
          &-minus {
            display: inline-block;
            background-color: $c;
            padding: 0;
            width: 16px;
            height: 2px;
            border-radius: 2px;
            background-color: $c;
            margin: 8px 0 0 42px;

            &::after {
              content: "";
              display: block;
              padding: 0;
              width: 16px;
              height: 2px;
              border-radius: 2px;
              background-color: $c;
              transform: rotate(90deg);
              transition: all 0.5s ease-in-out;
            }
          }
        }
      }
    }
  }

  .u_navigation--active {
    ul + a {
      .u_menu_item {
        &::after {
          background-color: $c--a;
        }

        &-minus {
          background-color: $c--a;

          &::after {
            background-color: $c--a;
            transform: rotate(180deg);
          }
        }
      }
    }
  }
}

@include respond(lg) {
  .u_header {
    ul {
      li,
      a {
        text-decoration: none;

        .u_menu_item {
          flex-wrap: wrap;
          cursor: pointer;
          color: #0b1524;
          font-weight: 600;
          font-size: 32px;
          line-height: 40px;
          display: block;
          padding: 12px 0;
          height: auto;
          align-items: center;

          @include respond(sm) {
            display: block;
            font-size: 19px;
            line-height: 32px;
            padding: 10px 0;
          }

          &:after {
            content: "";
            height: 4px;
            display: block;
            background-color: transparent;
            border-radius: 20px;
            position: relative;
            left: unset;
            padding: 0;
            width: calc(100% - 58px);
            margin: 8px 0 0;
            transition: background-color 0.5s ease;
          }
        }

        &:hover {
          .u_menu_item {
            &:after {
              background-color: transparent;
            }
          }
        }
      }
    }

    ul {
      li {
        ul + a {
          .u_menu_item {
            &-minus {
              display: inline-block;
              background-color: $c;
              padding: 0;
              width: 16px;
              height: 2px;
              border-radius: 2px;
              background-color: $c;
              margin: 8px 0 0 42px;

              &::after {
                content: "";
                display: block;
                padding: 0;
                width: 16px;
                height: 2px;
                border-radius: 2px;
                background-color: $c;
                transform: rotate(90deg);
                transition: all 0.5s ease-in-out;
              }
            }
          }
        }
      }

      .u_navigation--active {
        ul + a {
          .u_menu_item {
            &::after {
              background-color: $c--a;
            }

            &-minus {
              background-color: $c--a;

              &::after {
                background-color: $c--a;
                transform: rotate(180deg);
              }
            }
          }
        }
      }
    }
  }
}
