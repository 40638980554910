.App {
  display: block;
  min-height: 100vh;
}

[data-scroll] {
  // overflow: auto;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}
