.footer {
  width: 100%;
  height: fit-content;
  padding: 64px 0 73px;
  background-color: #0b1524;

  @media (max-width: 768px) {
    padding: 48px 0 30px;
  }

  .footer-container {
    display: flex;
    justify-content: space-between;
    gap: 30px;

    @media (max-width: 768px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: flex-start;
      gap: 0;
    }

    .footer-info {
      display: flex;
      flex-direction: column;

      .logo {
        margin-bottom: 15px;
      }

      .description {
        display: flex;
        flex-direction: column;
        margin-bottom: 26px;

        @media (max-width: 768px) {
          margin-bottom: 0;
        }

        p {
          font-weight: 600;
          font-size: 24px;
          line-height: 40px;
          color: #ffffff;

          @media (max-width: 550px) {
            font-size: 18px;
            line-height: 32px;
          }
        }

        span {
          font-weight: 400;
          font-size: 16px;
          line-height: 28px;
          color: #ffffff;

          @media (max-width: 550px) {
            font-size: 14px;
            line-height: 24px;
          }
        }
      }

      @media (max-width: 768px) {
        margin-bottom: 40px;
      }
    }

    .social-list {
      display: flex;
      flex-wrap: nowrap;
      margin-left: -9px;
      margin-bottom: 26px;

      &.mobile {
        display: none;
      }

      @media (max-width: 768px) {
        display: none;

        &.mobile {
          display: flex;
          margin-bottom: 32px;
        }
      }

      .social-list-item {
        display: flex;
        align-items: center;
        justify-content: center;
        width: 40px;
        height: 40px;
        border-radius: 50%;
        transition: 0.15s linear all;
        -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

        &:hover {
          background-color: rgba(53, 116, 214, 0.25);

          svg {
            fill: #3574d6;
          }
        }

        svg {
          fill: #ffffff;
        }
      }
    }

    .privacy-block {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      color: rgba(255, 255, 255, 0.5);

      &.mobile {
        display: none;
      }

      @media (max-width: 768px) {
        display: none;

        &.mobile {
          display: block;
        }
      }

      @media (max-width: 550px) {
        font-size: 14px;
        line-height: 16px;
      }

      a {
        text-decoration: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: rgba(255, 255, 255, 0.5);
        transition: 0.15s linear all;

        @media (max-width: 550px) {
          font-size: 14px;
          line-height: 16px;
        }

        &:hover {
          color: rgba(255, 255, 255, 1);
        }
      }
    }

    .footer-link-container {
      display: flex;
      gap: 40px;
      justify-content: space-between;
      width: 610px;

      @media (max-width: 768px) {
        width: 100%;
        margin-bottom: 50px;
        flex-wrap: wrap;
        column-gap: 10px;
        row-gap: 40px;
      }

      .link-list {
        display: flex;
        flex-direction: column;
        gap: 15px;

        a {
          width: fit-content;
          position: relative;
          text-decoration: none;
          font-weight: 600;
          font-size: 14px;
          line-height: 17px;
          color: #ffffff;
          transition: 0.15s linear all;
          -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

          @media (max-width: 550px) {
            font-size: 12px;
            line-height: 16px;
          }

          &::before {
            content: "";
            display: block;
            position: absolute;
            bottom: -4px;
            left: 0;
            height: 2px;
            width: 0;
            border-radius: 1px;
            background-color: #3574d6;
            transition: 0.15s linear all;
          }

          &:hover {
            color: #3574d6;

            &::before {
              width: 50%;
            }
          }
        }
      }
    }
  }
}
