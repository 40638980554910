@import "../../assets/scss/index.scss";

.u_container {
  position: relative;
  width: 100%;
  margin: 0 auto;
  max-width: $page-max-width + $page-padding * 2;
  padding: 0 $page-padding;

  @include respond("lg") {
    max-width: $page-max-width + $page-padding-lg * 2;
    padding: 0 $page-padding-lg;
  }

  @include respond("md") {
    max-width: $page-max-width + $page-padding-md * 2;
    padding: 0 $page-padding-md;
  }

  @include respond("sm") {
    max-width: $page-max-width + $page-padding-sm * 2;
    padding: 0 $page-padding-sm;
  }

  &--full_width {
    max-width: 100%;
  }

  &--small {
    max-width: $page-max-width-small + $page-padding * 2;

    @include respond("lg") {
      max-width: $page-max-width-small + $page-padding-lg * 2;
    }

    @include respond("md") {
      max-width: $page-max-width-small + $page-padding-md * 2;
    }

    @include respond("sm") {
      max-width: $page-max-width-small + $page-padding-sm * 2;
    }
  }
}
