@import "../../assets/scss/variables.scss";

.u_button {
  font-family: Montserrat;
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  padding: 11px 24px;
  border-radius: 28px;
  border: none;
  cursor: pointer;
  text-decoration: none;

  &--primary {
    background: $primary-bg;
    color: $primary-c;
  }

  &--secondary {
    background: $secondary-bg;
    color: $secondary-c;
  }

  &.headerBtn {
    width: 170px;
    height: 40px;
    padding: 0;

    &:hover {
      background: $hover-blue;
    }
  }

  &.mobHeaderBtn {
    width: 138px;
    height: 40px;
    background: $menu-icon-bg;
    color: $blue;
    font-size: 12px;
  }

  &.caseStudiesBtn {
    width: 210px;
    height: 40px;
    padding: 11px 0;

    img {
      position: relative;
      top: 2px;
    }

    @media (max-width: 425px) {
      font-size: 12px;
    }
  }
}
