@import "../../assets/scss/index.scss";

@keyframes rotating {
  from {
    -webkit-transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
  }
}

.loader-wrapper {
  width: 100%;
  height: 100vh;
  background: #fff;
  position: fixed;
  z-index: 9999;

  .preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 62px;
    height: 62px;
    margin-left: -31px;
    margin-top: -31px;
    border: #2562bd 4px solid;
    border-left-color: transparent;
    border-radius: 50%;
    animation: rotating 1s linear infinite;
  }
}
.page-preloader-container {
  position: relative;
  height: 500px;
  display: flex;
  justify-content: center;
  align-content: center;
  max-height: 100%;
  width: 100%;

  @include respond("lg") {
    height: 200px;
  }

  .preloader {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 62px;
    height: 62px;
    margin-left: -31px;
    margin-top: -31px;
    border: #2562bd 4px solid;
    border-left-color: transparent;
    border-radius: 50%;
    animation: rotating 1s linear infinite;
  }
}
