@import "../../../../assets/scss/index.scss";

.u_menu_card {
  margin: 9px 19px 42px;
  @include respond(md) {
    margin: 9px 0 24px;
    width: 100%;
  }
  &-icon {
    border-radius: 50%;
    background-color: $menu-icon-bg;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $primary-c;
    width: 40px;
    height: 40px;
    flex: 0 0 40px;
    margin: 0 10px 0 0;
    @include respond(sm) {
      margin: 0 16px 0 0;
    }

    i {
      fill: $blue;
    }

    img {
    }
  }
  &-label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: $c;
    display: flex;
    align-items: center;
    margin: 0 0 16px;
  }
  &-description {
    font-family: Montserrat;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 28px;
    color: $c;
    @include respond(sm) {
      display: none;
    }
  }

  &:hover {
    .u_menu_card-label {
      color: $blue;
    }
  }
}

@include respond(md) {
  .u_header {
    .u_menu_card {
      margin: 9px 0 9px;
      width: 100%;
      .u_menu_card-icon {
        border-radius: 50%;
        background-color: $menu-icon-bg;
        display: flex;
        justify-content: center;
        align-items: center;
        color: $primary-c;
        width: 40px;
        height: 40px;
        flex: 0 0 40px;
        margin: 0 10px 0 0;

        @include respond(sm) {
          margin: 0;
          background-color: transparent;
        }
      }
      .u_menu_card-label {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: $c;
        display: flex;
        align-items: center;
        margin: 0 0 16px;

        @include respond(sm) {
          font-size: 14px;
          line-height: 32px;
          font-weight: 400;
          margin: 0;
        }
      }
      .u_menu_card-description {
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 17px;
        color: $c;
        @include respond(sm) {
          display: none;
        }
      }
    }
  }
}
