@import "../../assets/scss/variables.scss";

.u_icon {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  fill: $bg;

  &--char {
    width: auto;
    height: auto;
    fill: currentColor;
  }
}
