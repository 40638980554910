@import "../../assets/scss/index.scss";

@keyframes showIn {
  to {
    opacity: 1;
  }
}

.fb-phone-container {
  display: flex;
  align-items: center;
  position: fixed;
  z-index: calc(1 / 0);
  right: 100px;
  bottom: 24px;
  height: 60px;
  opacity: 0;
  animation: showIn 0.3s ease-in-out;
  animation-delay: 2.5s;
  animation-fill-mode: forwards;

  @include respond(md) {
    right: 95px;
  }

  .desktop {
    padding: 8px 16px;
    background: #ebf1fb;
    border: 1px solid #dbe8fd;
    box-shadow:
      0 20px 25px -5px rgba(26, 32, 44, 0.1),
      0 10px 10px -5px rgba(26, 32, 44, 0.04);
    border-radius: 10px;

    a {
      display: flex;
      align-items: center;
      gap: 10px;
      text-decoration: none;

      span {
        font-weight: 400;
        font-size: 16px;
        line-height: 28px;
        color: $blue;
      }
    }
  }
}
