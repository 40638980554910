@import "../../../../assets/scss/index.scss";

.u_menu_button {
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: none;
  width: 40px;
  height: 40px;
  background: $blue;
  position: relative;
  color: $white;
  z-index: 2;

  &-open_child,
  &-close_child {
    width: 100%;
    height: 100%;
    display: flex;
    position: absolute;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    transition: opacity 0.5s ease;
  }

  &--opened & {
    &-open_child {
      opacity: 0;
    }

    &-close_child {
      opacity: 1;
    }
  }

  &-open_child {
    opacity: 1;
  }

  &-close_child {
    opacity: 0;
  }
}

.u_menu_button-circles {
  display: block;
  top: 0;
  right: 0;
  bottom: 0;
  position: absolute;
  left: 0;
  pointer-events: none;

  &::before,
  &::after {
    content: "";
    width: 40px;
    height: 40px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    top: 0;
    right: 0;
    border-radius: 50%;
    opacity: 1;
    pointer-events: none;
  }

  &::before {
    background-color: $primary-bg;
    transition:
      all 0.5s ease,
      opacity 0s ease 0s;
  }

  &::after {
    background-color: $bg;
    transition:
      all 0.25s ease,
      opacity 0s ease 0s;
  }
}

.u_menu_button--opened + .u_menu_button-circles {
  &::before,
  &::after {
    $scale: 65;
    $size: 40px * $scale;
    width: $size;
    height: $size;
    top: -($size / 2);
    right: -($size / 2);
    opacity: 0;

    @include respond(sm) {
      top: -($size / 2);
      right: -($size / 2);
    }
  }

  $menu-delay: 0.5s;

  &::before {
    transition:
      all 0.5s ease,
      opacity 0.1s ease 0.4s;
    @include respond(sm) {
      transition:
        all 0.5s ease,
        opacity 0.1s ease 0.25s;
    }
  }

  &::after {
    transition:
      all 1s ease,
      opacity 0.25s ease 0.4s;
    @include respond(sm) {
      transition:
        all 1s ease,
        opacity 0.25s ease 0.25s;
    }
  }
}
