@import "../../assets/scss/index.scss";

@keyframes margin {
  0% {
    margin: 0;
  }
  50% {
    margin: 50px 0 0;
  }
  100% {
    margin: 0;
  }
}

.u_header {
  transform: translate3d(0, 0, 0);
  position: fixed;
  width: 100%;
  z-index: 999;
  background: rgba($bg, 0);
  -webkit-overflow-scrolling: touch;
  top: 0;

  &--scrolled {
    background: rgba($bg, 0.8);
  }

  &__save-ukraine {
    transition: $time;
  }

  &--scrolled &__save-ukraine {
    margin-top: -40px;
  }

  &-mobile_toolbar {
    display: none;

    & > * {
      z-index: 5;
      margin: 0 0 0 35px;
    }
  }

  &-container {
    display: flex;
    justify-content: space-between;

    padding: 20px 0 0;
    width: 100%;
  }

  &-right {
    display: flex;
    text-align: right;
    white-space: nowrap;

    & > * {
      white-space: normal;
    }
  }
}

.u_navigation {
  display: inline-block;
  margin: -20px 0 0;
  font-family: Montserrat, sans-serif;

  ul {
    padding: 0;
    margin: 0;
    display: inline-block;

    & > li {
      display: inline-flex;

      .sub-menu {
        position: relative;
        display: flex;
        align-items: center;
        gap: 15px;

        img {
          display: none;
          position: relative;
          z-index: 1;
          top: -5px;
          width: 12px;
          height: 12px;
        }
      }

      &:hover {
        ul {
          display: flex;
        }

        span {
          color: $blue;

          @include respond(lg) {
            color: $blue-100;
          }
        }
      }

      &.hide {
        ul {
          display: none;
        }
      }

      > a {
        text-decoration: none;
      }

      ul {
        position: absolute;
        width: calc(100% - 30px);
        left: 15px;
        right: 15px;
        top: 79px;
        background: $bg;
        box-shadow: 0 8px 16px rgba(51, 51, 51, 0.1);
        border-radius: 0 0 8px 8px;
        display: none;
        flex-wrap: wrap;
        text-align: left;
        padding: 33px 7.5px 0;
        cursor: auto;

        li {
          flex: 0 0 25%;
          width: 25%;
          padding: 0;
          margin: 0;
          height: auto;

          &:after {
            display: none;
          }
        }
      }
    }
  }

  & &--active ul li a {
    pointer-events: all;
    width: 100%;
  }
}

.work-link {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  width: 116px;
  height: 40px;
  border-radius: 20px;
  margin-left: 20px;
  border: 2px solid $blue;
  text-decoration: none;
  transition: 0.15s all linear;

  @include respond(lg) {
    margin-left: 0;
  }

  &:hover {
    background-color: $hover-blue;
    border-color: $hover-blue;

    span {
      color: $white;
    }

    img {
      filter: invert(100%) sepia(77%) saturate(0%) hue-rotate(346deg) brightness(112%) contrast(101%);
    }
  }

  span {
    font-weight: 600;
    font-size: 14px;
    line-height: 17px;
    color: $blue;

    @include respond(lg) {
      font-size: 12px;
      line-height: 15px;
    }
  }

  img {
    filter: invert(48%) sepia(18%) saturate(2858%) hue-rotate(186deg) brightness(84%) contrast(100%);
  }
}

// HEADER ADAPTIVE

.u_header {
  @include respond("lg") {
    padding: 0;
  }

  @media (max-width: 1100px) {
    display: flex;
    flex-direction: column;

    &--opened {
      background-color: $bg;
      height: 100%;
    }

    &-mobile_toolbar {
      flex: 0 0 auto;
      display: flex;
      justify-content: flex-end;
    }

    &-container-wrap {
      height: 20%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
    }

    &-container {
      height: 20%;
      flex-grow: 1;
      display: flex;
      flex-direction: column;
      padding: 20px 0;
      width: 100%;
    }

    &-left {
      display: flex;
      justify-content: space-between;
    }

    &-right {
      position: relative;
      text-align: unset;
      overflow: hidden;
      visibility: visible;
      display: flex;
      flex-direction: column;
      margin: 0;
      flex-grow: 0;
      opacity: 0;
      height: 0;

      transition: opacity 0.3s;
    }

    &--opened &-right {
      margin: 10px 0 0;
      visibility: visible;
      height: 20%;
      flex-grow: 1;
      opacity: 1;
      overflow-y: auto;
    }

    &-right > * {
      flex-shrink: 0;
    }

    &-right::after {
      content: "";
      position: absolute;
      z-index: 2;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100%;
      background-color: white;
      display: block;

      transition: height 0.5s;
    }

    &--opened &-right::after {
      height: 0;
      transition-delay: 0.3s;
    }
  }

  @include respond(sm) {
    &-container {
      padding: 10px 0;
    }

    &--opened &-right {
    }
  }
}

// NAVIGATION ADAPTIVE

.u_navigation {
  @include respond(lg) {
    margin: unset;
    background-color: $bg;
    display: block;
    overflow: hidden;
    visibility: hidden;
    transition: all 0s ease 0s;
    padding: 20px 0 10px;

    & + .get-in-touch {
      display: none;
    }

    &--opened {
      visibility: visible;
      transition: all 1s ease 0.2s;
    }

    ul {
      flex-direction: column;
      flex-grow: 0;
      justify-content: flex-start;
      align-items: flex-end;
    }

    & ul > li {
      .sub-menu {
        width: 100%;

        img {
          display: block;
        }
      }

      flex-wrap: wrap;
      width: 100%;

      > a {
        padding: 0 58px 0 0;
        text-decoration: none;
        width: 100%;
      }
    }

    & ul li ul {
      display: flex;
      max-height: 0;
      transition:
        max-height 2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        opacity 1s ease;
      position: static;
      order: 2;
      opacity: 0;
      padding: 0;
      flex-direction: column-reverse;
      z-index: 0;
      pointer-events: none;

      width: 100%;
      box-shadow: none;
      flex-wrap: nowrap;

      $max-imaging-count: 20;
      @for $i from 1 through $max-imaging-count {
        li:nth-of-type(#{$i}) {
          order: $max-imaging-count - $i;
        }
      }

      & + a {
        display: inline-block;
        position: relative;
      }

      li {
        flex: unset;
        width: 100%;
      }
    }

    & &--active ul {
      animation-name: margin;
      animation-duration: 1s;
      animation-iteration-count: 1;
      animation-timing-function: ease;
      max-height: 1400px;
      opacity: 1;
      pointer-events: initial;
      transition:
        max-height 2s cubic-bezier(0.175, 0.885, 0.32, 1.275),
        opacity 2s ease;
    }
  }

  @include respond(lg) {
    padding: 10px 0 10px;
  }
}

li {
  &.close-menu-trigger {
    @include respond(lg) {
      .u_menu_item {
        &::before {
          content: "";
          position: absolute;
          bottom: 20px;
          display: block;
          width: 0;
          height: 3px;
          border-radius: 2px;
          background-color: $blue;
          transition: 0.2s linear all;
        }
      }

      &.u_navigation--active {
        .u_menu_item {
          &::before {
            width: 80%;
          }
        }
      }
    }
  }
}

.logo-mobile {
  img {
    max-width: 40px;
  }
}
.logo-primary {
  img {
    max-width: 200px;
  }
}
