@import "../../assets/scss/index";

.save-ukraine {
  @include respond("xl") {
    &__content {
      &::after {
        display: none;
      }
    }
  }

  @include respond("lg") {
    &__content {
      &::before {
        left: -76px;
      }
    }
  }

  @include respond("md") {
    &__content {
      &::before {
        left: -80px;
      }
    }
  }

  @include respond("sm") {
    &__content {
      &::before {
        left: -84px;
      }
    }

    &__title {
      font-size: 14px;
      line-height: 17px;
    }
  }
}
