@import "../../assets/scss/index";

.requestaQuote {
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 57px 0 50px;
  position: relative;

  @include respond("sm") {
    padding: 23px 0 40px;
  }

  &-contacts {
    position: relative;
    display: flex;
    flex-direction: column;
    height: 306px;
    width: 390px;
    background: #fff;
    border-radius: 20px;
    color: #0b1524;
    padding-left: 30px;
    margin: 30px auto 0;

    @include respond("sm") {
      margin-top: 0;
      padding-left: 16px;
      width: 100%;
      height: 220px;
    }

    h3 {
      font-size: 24px;
      font-weight: 800;
      line-height: 56px;
      letter-spacing: 0.5px;
      color: #0b1524;
      margin: 30px 0px 24px;
      width: 100%;

      @include respond("sm") {
        margin-top: 15px;
        font-size: 18px;
        line-height: 27px;
      }
    }

    &-item {
      display: flex;
      align-items: center;
      margin-bottom: 35px;

      &:hover {
        a {
          color: #3574d6;
        }

        img {
          filter: invert(46%) sepia(43%) saturate(4578%) hue-rotate(201deg) brightness(88%) contrast(90%);
        }
      }

      @include respond("sm") {
        margin-bottom: 10px;
        height: 32px;
      }

      img {
        margin-right: 16px;
        min-width: 24px;
      }

      a {
        font-size: 18px;
        line-height: 32px;
        color: #0b1524;
        text-decoration: none;
        transition: 0.15s linear all;

        @include respond("lg") {
          font-size: 16px;
          margin-right: 40px;
        }

        @include respond("lg") {
          font-size: 14px;
        }
      }
    }

    &-image {
      position: absolute;
      top: 159px;
      right: -24px;
      width: 68px;
      height: 81px;

      & .decorator {
        width: 100%;
        height: 100%;
      }

      @include respond("sm") {
        top: unset;
        bottom: 0;
        right: 12px;
      }
    }
  }

  @media screen and (max-width: 768px) {
    padding-top: 30px;
  }

  .title {
    font-family: "Montserrat";
    font-size: 40px;
    font-weight: 800;
    line-height: 56px;
    letter-spacing: 1px;
    color: #0b1524;

    margin-bottom: 24px;

    @media screen and (max-width: 768px) {
      font-size: 30px;
      line-height: 1.4;
      margin-top: 50px;
      margin-bottom: 50px;
    }

    @include respond("md") {
      min-width: auto;
      font-size: 32px;
    }

    @include respond("sm") {
      font-size: 24px;
      margin-top: 15px;
      margin-bottom: 15px;
      display: flex;
      justify-content: center;
    }
  }

  .subtitle {
    font-size: 18px;
    line-height: 32px;
    letter-spacing: 0.5px;
    color: #0b1524;
    margin-bottom: 30px;

    @include respond("lg") {
      font-size: 20px;
    }

    @include respond("md") {
      font-size: 18px;
    }

    @include respond("sm") {
      font-size: 14px;
      line-height: 21px;
      text-align: center;
      letter-spacing: 0.5px;
      margin-bottom: 16px;
    }
  }

  .wrapRequestForm {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex: 0 0 70%;
    max-width: 70%;
    padding-right: 130px;

    @include respond("lg") {
      flex: 0 0 100%;
      max-width: 100%;
      padding-bottom: 30px;
    }

    @include respond("sm") {
      padding-right: 0px;
    }

    .input-row {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: space-between;
    }

    .input-container {
      display: flex;
      flex-direction: column;
      position: relative;
      width: 49%;
      margin: 0 0 25px;
      border-radius: 4px;

      &.attach {
        width: fit-content;
        margin-right: -16px;
        margin-bottom: 0;

        @media (max-width: 450px) {
          width: 100%;
        }

        .attach-info {
          display: flex;

          @media (max-width: 450px) {
            justify-content: center;
          }

          .attach-file {
            display: flex;
            visibility: visible;
            align-items: center;
            justify-content: center;
            width: 124px;
            height: 34px;
            transition: 0.15s linear background-color;
            cursor: pointer;
            border-radius: 4px;
            -webkit-tap-highlight-color: rgba(255, 255, 255, 0);

            &.hide {
              width: 0;

              img,
              span {
                display: none;
              }
            }

            &:hover {
              background-color: #ebf1fb;

              img {
                filter: invert(46%) sepia(43%) saturate(4578%) hue-rotate(201deg) brightness(88%) contrast(90%);
              }

              span {
                color: #3574d6;
              }
            }

            @media (max-width: 450px) {
              margin-bottom: 30px;
            }

            img {
              margin-right: 8px;
            }

            input[type="file"] {
              display: none;
            }

            span {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: #858a91;
            }
          }

          .attached-file {
            height: 34px;
            margin-right: 16px;
            display: flex;
            align-items: center;
            flex-wrap: nowrap;
            gap: 8px;

            .clear {
              cursor: pointer;
            }

            p {
              font-style: normal;
              font-weight: 600;
              font-size: 14px;
              line-height: 17px;
              color: #3574d6;
            }
          }
        }

        .inputHelper {
          top: unset;
          bottom: -12px;
          margin-right: 16px;
          white-space: nowrap;

          @media (max-width: 450px) {
            bottom: 18px;
          }
        }
      }

      p {
        color: #0b1524;
        font-family: "Montserrat";
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        padding-bottom: 10px;

        @include respond("sm") {
          font-size: 14px;
        }
      }

      textarea {
        &.input {
          height: 100px;

          @include respond("sm") {
            height: 60px;
          }
        }
      }

      @media screen and (max-width: 480px) {
        width: 100%;
        margin-bottom: 16px;
      }

      &.error {
        h1 {
          color: $red;
        }

        .input {
          border-color: $red;

          &::placeholder {
            color: $red;
          }
        }

        .inputHelper {
          display: block;
        }
      }

      .inputHelper {
        display: none;
        position: absolute;
        top: calc(100% + 5px);
        right: 0;
        font-size: 14px;
        text-align: right;
        color: red;

        @include respond("sm") {
          font-size: 12px;
        }
      }

      .input {
        width: 100%;
        font-family: Montserrat;
        font-size: 14px;
        font-weight: 600;
        line-height: 17px;
        min-height: 50px;
        padding: 10px 22px;
        border: 1px solid #c2c4c8;
        border-radius: 5px;
        background-color: rgba(255, 255, 255, 0.1);

        &::-webkit-input-placeholder {
          color: #858a91;
        }
        &::-moz-placeholder {
          color: #858a91;
        }
        &:-ms-input-placeholder {
          color: #858a91;
        }
        &:-moz-placeholder {
          color: #858a91;
        }

        @include respond("sm") {
          min-height: 40px;
          font-size: 12px;
          padding-left: 15px;
        }

        &textarea {
          min-height: 130px;
          resize: vertical;
        }
      }

      &.fullWidth {
        width: 100%;
        margin-right: 0;
      }
    }

    .buttons-wrap {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      margin-top: 20px;

      @media (max-width: 450px) {
        margin-top: 5px;
        justify-content: center;
        flex-direction: column-reverse;
      }
    }

    button[type="submit"] {
      font-family: "Montserrat";
      font-weight: 600;
      display: block;
      min-height: 40px;
      min-width: 190px;
      border-radius: 20px;
      border: none;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      transition: 0.2s;
      z-index: 9;
      background-color: $blue;
      color: $white;
      cursor: pointer;

      &:hover {
        background-color: $hover-blue;
      }

      @include respond("sm") {
        min-width: 210px;
        font-size: 12px;
        line-height: 15px;
      }
    }
  }
}

.requestFormRow {
  display: flex;
  flex-wrap: wrap;
}
