@import "../../assets/scss/index";

.save-ukraine {
  height: 40px;
  background: #ebf1fb;

  &__content {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      position: relative;
      z-index: 2;
    }

    &::before {
      content: "";
      position: absolute;
      z-index: 1;
      left: -70px;
      top: 0;
      width: 180px;
      height: 40px;
      background: url("../../assets/images/saveUkraine/pattern-1.png") no-repeat center;
      background-size: contain;
    }

    &::after {
      content: "";
      position: absolute;
      right: -280px;
      top: 0;
      width: 310px;
      height: 40px;
      background: url("../../assets/images/saveUkraine/pattern-2.png") no-repeat center;
      background-size: contain;
    }
  }

  &__title {
    font-style: normal;
    font-weight: 900;
    font-size: 16px;
    line-height: 20px;
    text-transform: capitalize;
    color: #0b1524;
  }

  &__flag {
    width: 42px;
    height: 40px;
    background: url("../../assets/images/saveUkraine/flag-ua.png") no-repeat center;
    background-size: contain;
  }
}
