.header-desktop {
  position: relative;
  color: #fff;
  z-index: 99;
  .sticky-header {
    position: absolute;
    z-index: 5;
    top: 0;
    left: 0;
    right: 0;
    height: 80px;
    background-color: transparent;
    z-index: 9;
    &.is-sticky {
      position: fixed;
      background-color: #0b1524;
      .menu-logo .logo img {
        filter: none !important;
      }
    }
    .sticky-header-backdrop {
      display: none;
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
      @media (-moz-touch-enabled: 1), (pointer: coarse) {
        display: block;
      }
    }
    .main-wrapper {
      display: flex;
      position: relative;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      z-index: 9;
      padding: 0 10px;
      max-width: calc(1280px - (10px * 2));
      .menu-logo {
        display: flex;
        flex: 1;
        .logo {
          display: flex;
          align-items: center;
          img {
            display: block;
            width: 204px;
            filter: grayscale(100%) brightness(200%);
            transition: all 0.3s;
          }
        }
      }

      .menu-header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100%;
        flex: 3;
        .menu-item {
          display: block;
          margin: 0 20px;
          text-align: center;
          height: 100%;
          transition: all 0.5s ease;
          box-shadow: inset 0px -2px 0px 0px rgba(53, 116, 214, 0);
          &.active,
          &:hover {
            box-shadow: inset 0px -2px 0px 0px rgba(53, 116, 214, 1);
          }
          .menu-item-link {
            display: flex;
            align-items: center;
            height: 100%;
            font-size: 14px;
            text-decoration: none;
            color: #fff;
            text-transform: uppercase;
            &:hover {
              color: #aaa;
            }
          }
        }
      }

      .menu-requestBtn {
        display: flex;
        flex: 1;
        justify-content: flex-end;
        .get-in-touch {
          position: relative;
          font-family: Montserrat;
          font-size: 13px;
          font-weight: bold;
          padding: 5px 0;
          letter-spacing: 2px;
          color: #fff;
          background: transparent;
          border: none;

          text-transform: uppercase;
          cursor: pointer;
          outline: none;
          &::after {
            content: "";
            display: block;
            position: absolute;
            top: 100%;
            left: 0;
            right: 0;
            height: 2px;
            background: #3574d6;
            transition: all 0.3s;
          }
          &:hover::after {
            right: 100%;
          }
        }
      }
    }
  }

  .hoverMenu {
    display: block;
    position: absolute;
    width: 100%;
    left: 0;
    background-color: #0b1524;
    border-top: solid 2px #14314e;
    padding: 40px 0;
    z-index: 1;
    height: 0;
    top: 0px;
    opacity: 0;
    transition:
      height,
      top,
      opacity 0.5s ease;
    &.open {
      height: auto;
      top: 80px;
      opacity: 1;
    }
    .hoverMenuContainer {
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 1050px;
      margin: 0 auto;
      .hoverMenuData {
        display: flex;
        .hoverMenuData-item {
          display: flex;
          margin-right: 50px;
          .hoverMenuItemImages {
            width: 120px;
            min-width: 120px;
            height: 120px;
            margin-right: 30px;
          }
          .hoverMenuItemContent {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            max-width: 250px;
            .hoverMenuItemContentTitle {
              font-size: 16px;
              font-weight: bold;
              color: #fff;
              text-transform: uppercase;
              line-height: 1.43;
              margin-bottom: 20px;
            }
            .hoverMenuItemContentDescription {
              font-size: 14px;
              line-height: 1.43;
              color: #ffffff;
              margin-bottom: 15px;
              opacity: 0.8;
            }
            .readMoreBtn {
              display: flex;
              align-items: center;
              font-size: 13px;
              letter-spacing: 2px;
              color: #fff;
              text-decoration: none;
              font-weight: bold;
              text-transform: uppercase;
              padding-bottom: 5px;
              transition: color 0.3s ease-in;
              &:hover {
                color: #3574d6;
                .chevron {
                  opacity: 1;
                }
              }
            }
          }
        }
      }

      .hoverMenuList {
        padding: 0;
        margin: 0;
        list-style-type: none;
        margin-right: 40px;
        width: 100%;
        max-width: 230px;
        border-left: 2px solid #14314e;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        justify-content: center;
        li {
          cursor: pointer;
          &:not(:last-child) {
            margin-bottom: 24px;
          }
          a {
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;
            height: 16px;
            font-family: Montserrat;
            font-size: 12px;
            white-space: nowrap;
            font-weight: 500;
            text-transform: uppercase;
            color: #ffffff;
            text-decoration: none;
            transition: 0.5s ease color;
            &:hover {
              color: #3574d6;
              .chevron {
                opacity: 1;
              }
            }
          }
        }
      }

      .chevron {
        opacity: 0;
        margin-left: 5px;
        transition: opacity 0.3s ease-in;
        &::before {
          border-style: solid;
          border-width: 0.2em 0.2em 0 0;
          content: "";
          display: inline-block;
          height: 0.45em;
          left: 0.15em;
          position: relative;
          top: 0.15em;
          transform: rotate(-45deg);
          vertical-align: top;
          width: 0.45em;
        }
        &.right:before {
          left: 0;
          transform: rotate(45deg);
        }
      }
    }
  }
}

.header-mobile {
  display: none;
  height: 70px;
  position: fixed;
  z-index: 5;
  top: 0;
  left: 0;
  right: 0;
  z-index: 99;

  .burger-menu-button {
    width: 34px;
    height: 22px;
    position: relative;
    transform: rotate(0deg);
    transition: 0.5s ease-in-out;
    cursor: pointer;
    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: #fff;
      border-radius: 9px;
      opacity: 1;
      left: 0;
      transform: rotate(0deg);
      transition: 0.25s ease-in-out;
      &:nth-child(1) {
        top: 0px;
      }
      &:nth-child(2) {
        top: 11px;
      }
      &:nth-child(3) {
        top: 22px;
      }
    }

    &.isOpen {
      position: absolute;
      top: 0;
      right: 15px;
      margin: 30px 0 80px auto;
    }

    &.isOpen span:nth-child(1) {
      top: 11px;
      transform: rotate(135deg);
    }
    &.isOpen span:nth-child(2) {
      opacity: 0;
      left: -34px;
    }
    &.isOpen span:nth-child(3) {
      top: 11px;
      transform: rotate(-135deg);
    }
  }

  .burger-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 20px;
    height: 100%;
    transition: background 0.3s ease-in-out;
    &.is-sticky {
      background: #0b1524;
    }
    .logo {
      width: 35px;
      height: 40px;
    }
  }

  .accordion {
    width: 100%;
    padding-top: 132px;
    margin-bottom: 30px;
  }
  .accordion__item {
    margin-bottom: 25px;
    .accordion__title {
      display: block;
      font-size: 18px;
      color: #fff;
      text-transform: uppercase;
      outline: none;
      position: relative;
      &.closed {
        &::after {
          content: "+";
        }
      }
      &::after {
        content: "-";
        position: absolute;
        right: 0;
        font-size: 18px;
      }
    }
    .accordion__body {
      flex-direction: column;
      a {
        padding-left: 20px;
        text-transform: uppercase;
        margin: 12px 0;
        font-size: 12px;
        display: block;
        color: #fff;
        text-decoration: none;
        line-height: 1.2;
      }
    }
  }
  .mobile-menu-item {
    display: block;
    font-size: 18px;
    color: #fff;
    text-transform: uppercase;
    text-decoration: none;
  }

  .get-in-touch {
    display: block;
    position: relative;
    font-family: Montserrat;
    font-size: 13px;
    font-weight: bold;
    padding: 5px 0;
    margin: auto auto 80px;
    letter-spacing: 2px;
    color: #fff;
    background: transparent;
    border: none;
    text-transform: uppercase;
    cursor: pointer;
    outline: none;
    &::after {
      content: "";
      display: block;
      position: absolute;
      top: 100%;
      left: 0;
      right: 0;
      height: 2px;
      background: #3574d6;
      transition: all 0.3s;
    }
    &:hover::after {
      right: 100%;
    }
  }
}

.formWrap {
  .requestaQuote {
    padding: 40px 20px 0;
    &-contacts {
      flex: 0 0 100%;
      max-width: 100%;
      flex-direction: row;
      flex-wrap: wrap;
      padding: 0;
      h5 {
        margin-bottom: 10px;
      }
      &-item {
        margin-right: 40px;
      }
    }
  }
  .requestFormRow {
    flex-direction: column;
  }
  .wrapRequestForm {
    margin-bottom: 20px;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media screen and (max-width: 1024px) {
  .header-mobile {
    display: block;
  }
  .header-desktop {
    display: none;
  }
}
