.modalDialog {
  display: flex;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 999;
  overflow-y: auto;

  .modalDialog-backdrop {
    position: fixed;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(11, 21, 36, 0.5);
  }

  .modalDialog-container {
    position: relative;
    height: auto;
    margin: auto;
    max-width: 1000px;
    width: 100%;
    box-shadow: 0 0 50px 0 rgba(0, 0, 0, 0.25);
    background-image: linear-gradient(to top, #142744, #0c6b89);
    z-index: 12;

    .modalDialog-close {
      display: block;
      position: absolute;
      top: -48px;
      right: 0;
      width: 16px;
      height: 16px;
      cursor: pointer;
      z-index: 1;

      @media (max-width: 1050px) {
        right: 10px;
      }

      @media (max-width: 768px) {
        top: -30px;
        width: 14px;
        height: 14px;
      }
    }
  }
}
